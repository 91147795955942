<footer>
	<div class="container">
		<div class="footer-widget">
			<div class="row">
				<div class="col-lg-4 col-sm-5 order-1">
					<div class="widget site-info-widget">
						<!-- <div class="footer-logo">
							<img style="max-width: 62%;" src="../../../../assets/img/Real-Life-Logo.png" alt="Finsa">
						</div> -->
						<iframe name="f25f865775291de" data-testid="fb:page Facebook Social Plugin"
							title="fb:page Facebook Social Plugin" allowtransparency="true" allowfullscreen="true"
							scrolling="no" allow="encrypted-media"
							style="border: medium none; visibility: visible; width: 263px; height: 300px;"
							src="https://www.facebook.com/v2.5/plugins/page.php?adapt_container_width=true&amp;app_id=297186066963865&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1929694de177b6%26domain%3Dwww.reallife.lk%26origin%3Dhttps%253A%252F%252Fwww.reallife.lk%252Ff2df011737620fe%26relation%3Dparent.parent&amp;container_width=263&amp;height=300&amp;hide_cover=false&amp;hide_cta=true&amp;href=https%3A%2F%2Fwww.facebook.com%2FRealLifeSL%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline%2C%20events%2C%20messages&amp;width=340"
							class="" width="340px" height="300px" frameborder="0"></iframe>
						<!-- <p>Power of choice is untrammelled & when nothing prevents our being able</p> -->
						<!-- <ul class="social-links">
							<li><a style="font-size: x-large;" href="https://www.facebook.com/RealLifeSL"
									target="_blanck"><i class="fab fa-facebook-f"></i></a></li>
							<li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-behance"></i></a></li>
								<li><a href="javascript:void(0)"><i class="fab fa-dribbble"></i></a></li>
						</ul> -->
					</div>
				</div>
				<!-- <div class="col-lg-8 col-sm-7 order-2">
						<div class="widget newsletter-widget">
							<h4 class="widget-title">Subscribe Our Newsletters</h4>
							<div class="newsletter-form">
								<form action="#">
									<input type="email" placeholder="Enter Your Email">
									<button type="button" class="main-btn">Subscribe Now</button>
								</form>
							</div>
						</div>
					</div> -->
				<div class="col-lg-3 col-sm-6 order-3">
					<div class="widget nav-widget">
						<h4 class="widget-title">Quick Links</h4>
						<ul>
							<li><a routerLink='/'>Home</a></li>
							<li><a routerLink='/contribute'>Contribute</a></li>
							<li><a routerLink='/events'>Events</a></li>
							<li><a routerLink='/gallery'>Gallery</a></li>
							<!-- <li><a href="javascript:void(0)">Business & Consulting</a></li>
								<li><a href="javascript:void(0)">Financial Planning</a></li> -->
						</ul>
					</div>
				</div>
				<div class="col-lg-5 order-lg-4 order-5">
					<div class="row">
						<div class="col-lg-6 col-sm-6">
							<div class="widget nav-widget">
								<h4 class="widget-title">Company</h4>
								<ul>
									<li><a routerLink='/about-us'>About Us</a></li>
									<li><a routerLink='/realLifeShip'>Real-Life-Ship</a></li>
									<li><a routerLink='/realLifeTeam'>Real Life Team</a></li>
									<li><a routerLink='/products'>Products</a></li>
									<li><a routerLink='/get-in-touch'>Get In Touch</a></li>

								</ul>
							</div>
						</div>
						<div class="col-lg-6 col-sm-6">
							<div class="widget contact-widget">
								<h4 class="widget-title">Contact Us</h4>
								<ul class="contact-infos">
									<!-- <li>
											<a href="tel:+0123456789">
												<i class="far fa-phone color"></i>
												+012 (345) 6789
											</a>
										</li> -->
										
										
									<li> 
										<a href="mailto:info@reallife.lk">
											<i style="color: #f1455d;" class="far fa-envelope-open "></i>
											info@reallife.lk
										</a>
									</li>
									<li>
										<a href="https://www.facebook.com/RealLifeSL/" target="_blanck">
											<i style="color: #f1455d;" class="fab fa-facebook-f"></i>
											Facebook
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/real_life_sri_lanka/" target="_blanck">
											<i style="color: #f1455d;" class="fab fa-instagram"></i>
											Instagram
										</a>
									</li>
									<li>
										<a href="https://twitter.com/real_lanka" target="_blanck">
											<i style="color: #f1455d;" class="fab fa-twitter-square"></i>
											Twitter
										</a>
									</li>
									<li>
										<a href="https://www.linkedin.com/company/reallifesrilanka/" target="_blanck">
											<i style="color: #f1455d;" class="fab fa-linkedin"></i>
											LinkedIn
										</a>
									</li>
									<li>
										<a href="https://www.youtube.com/c/RealLifeSriLanka/" target="_blanck">
											<i style="color: #f1455d;" class="fab fa-youtube"></i>
											YouTube 

										</a>
									</li>
									<li>
										<a href="https://www.youtube.com/channel/UCi5ld4A8IZ03UMq5N2-K7lQ" target="_blanck">
											<i style="color: #f1455d;" class="fab fa-youtube-square"></i>
											YouTube Life coaching

										</a>
									</li>

									

								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-lg-4 col-sm-6 order-lg-5 order-4">
						<div class="widget insta-feed-widget">
							<h4 class="widget-title">Follow Instagram</h4>
							<div class="insta-images">
								<div class="insta-img" style="background-image: url(assets/img/instagram/01.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/02.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/03.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/04.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/05.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
								<div class="insta-img" style="background-image: url(assets/img/instagram/06.jpg);">
									<a href="javascript:void(0)">
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
					</div> -->
			</div>
		</div>
		<div class="footer-copyright">
			<p class="copyright-text">
				<span>&copy;&nbsp;2020<a class="color" href="javascript:void(0)">&nbsp;Real Life</a></span>
				<span>Powered by <a class="color" href="https://www.webstazy.one/" target="_blanck">&nbsp;Webstazy
						One</a></span>
			</p>

			<a href="javascript:void(0)" class="back-to-top"><i class="far fa-angle-up color"></i></a>
		</div>
	</div>



	<!-- Lines -->
	<img src="assets/img/lines/01.png" alt="line-shape" class="line-one">
	<img src="assets/img/lines/02.png" alt="line-shape" class="line-two">
</footer>