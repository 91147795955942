<app-header1></app-header1>



<!--====== Breadcrumb part Start ======-->
<section class="breadcrumb-section bg-img-c" style="background-image: url(../../../../assets/img/About\ Us.png);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1 class="page-title"><a style="color: #f2576d;">A</a>bout Us</h1>
            <ul>
                <p
                    style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
                    Words can inspire, thoughts can provoke, but only action truly brings. Our greatest glory is your
                    success.</p>
            </ul>
        </div>
    </div>
    <div class="breadcrumb-shapes">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
    </div>
</section>
<!--====== Breadcrumb part End ======-->

<!--====== About Section start ======-->
<section class="about-section about-illustration-img section-gap">
    <div class="container">
        <div class="illustration-img">
            <img src="../../../../assets/img/illustration/About Us main.png" alt="Image">
        </div>
        <div class="row no-gutters justify-content-lg-end justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="about-text">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag color">About Us</span>
                        <h2 class="title">The club at Real Life <br></h2>
                    </div>
                    <p class="mb-25">
                        The club at Real Life is a dynamic social network in Sri Lanka to support families towards a
                        healthier and happier lifestyle by delivering our stereotyped family life coaching programs
                        while helping to celebrate their life movements with planning and organizing birthdays, wedding
                        anniversary, achievements etc.

                    </p>
                    <p>
                        Real Life is a creative, innovative and multi cultural space that has been carefully curated for
                        the continually curious and those motivated by social well-being towards happier lifestyle by
                        strengthening family relationships.
                    </p>

                    <p style="padding-top: 1rem;">We recreate your LIFE to be a REAL LIFE…</p>
                    <!-- <ul class="about-list">
                        <li> <i class="far fa-check"></i> Business & Consulting Agency</li>
                        <li> <i class="far fa-check"></i> Awards Winning Business Comapny</li>
                    </ul> 
                    <a routerLink='/about-us' class="main-btn">Learn More</a>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== About Section end ======-->




<!-- ============== Mission Vission Slogan Start ===============-->
<section class="wcu-section box-style">
    <br><br>
    <div class="container">
        <div class="wcu-inner">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6">
                    <div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
                        data-wow-delay="400ms">
                        <img src="../../../../assets/img/illustration/vision.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag color">What is our</span>
                            <h2 class="title">Vision</h2>
                        </div>
                        <p>
                            සැමට සෙනෙහසින් බැඳුනු, සතුටින් පිරුනු, සවිමත් පවුලක්…
                        </p>
                        <p style="padding-top: 1.6rem;">
                            To recreate a LIFE towards a happier lifestyle with straightening family relationships.
                        </p>
                        <!-- <ul class="wcu-list">
                            <li>
                                <i class="far fa-check-circle"></i> Specialist Company Advisor Team
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Understanding Machines OpenS tandard

                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Can Data Visualization Improve Mobile
                            </li>
                        </ul> 

                        <a routerLink='/contact-us' class="main-btn main-btn-4">Join With Us</a> -->
                    </div>
                </div>
            </div>
            <img src="assets/img/lines/03.png" alt="shape" class="line-shape-one">
            <img src="assets/img/lines/04.png" alt="shape" class="line-shape-two">
        </div>
    </div>
    <br><br>
</section>
<section class="wcu-section box-style">
    <div class="container">
        <div class="wcu-inner">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-7">
                    <div class="wcu-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag color">What is our</span>
                            <h2 class="title">Mission</h2>
                        </div>
                        <p>
                            එන්න … සැබෑ ජීවිතය අත්විඳින්න… </p>
                        <p>සැමට සෙනෙහසින් බැඳුනු, සතුටින් පිරුනු, සවිමත් පවුලක්</p>
                        <p>භාවාත්මක සත්කාරය තුලින් ගොඩ නගමු.</p>

                        <p style="padding-top: 1.6rem;">Let's recreate mind-set & lifestyle through emotional therapy of Real Life 
                        </p>
                        <p>edutainment & Life coaching for a happier
                        </p>
                        <p>Lifestyle with strong family relationships towards a sustainable future…</p>
                        <p style="padding-top: 1rem;">Now you are living…</p>



                        <!-- <ul class="wcu-list">
                            <li>
                                <i class="far fa-check-circle"></i> Specialist Company Advisor Team
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Understanding Machines OpenS tandard

                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Can Data Visualization Improve Mobile
                            </li>
                        </ul>

                        <a routerLink='/contact-us' class="main-btn main-btn-4">Join With Us</a> -->
                    </div>
                </div>
                <div class="col-lg-5 col-md-10">
                    <div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
                        data-wow-delay="400ms">
                        <img style="max-width: 109% !important;" src="../../../../assets/img/illustration/mission.png" alt="Image">
                    </div>

                </div>
            </div>
            <img src="assets/img/lines/03.png" alt="shape" class="line-shape-one">
            <img src="assets/img/lines/04.png" alt="shape" class="line-shape-two">
        </div>
    </div>
    <br><br>
</section>
<section class="wcu-section box-style">
    <div class="container">
        <div class="wcu-inner">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6">
                    <div class="wcu-image text-center text-lg-left wow fadeInUp" data-wow-duration="1500ms"
                        data-wow-delay="400ms">
                        <img src="../../../../assets/img/illustration/slogan.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="wcu-text">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag color">What is our</span>
                            <h2 class="title">Slogan</h2>
                        </div>

                        <p>අදට ඔබින ජීවන රටාවක්…</p>
                        <p>හෙටට වටින නව පරපුරක්…</p>
                        <p>සැබෑ ජීවිතයක් මත ගොඩ නගමු…</p>



                        <p style="padding-top: 1.6rem;">Recreating a lifestyle for a</p>
                        <p>sustainable future through </p>
                        <p>Real Life…</p>

                        <!-- <ul class="wcu-list">
                            <li>
                                <i class="far fa-check-circle"></i> Specialist Company Advisor Team
                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Understanding Machines OpenS tandard

                            </li>
                            <li>
                                <i class="far fa-check-circle"></i> Can Data Visualization Improve Mobile
                            </li>
                        </ul>

                        <a routerLink='/contact-us' class="main-btn main-btn-4">Join With Us</a> -->
                    </div>
                </div>
            </div>
            <img src="assets/img/lines/03.png" alt="shape" class="line-shape-one">
            <img src="assets/img/lines/04.png" alt="shape" class="line-shape-two">
        </div>
    </div>
    <br><br>
</section>
<!-- ============== Mission Vission Slogan End ===============-->



<!--====== Service Section Start ======-->
<!-- <section class="service-section grey-bg service-line-shape section-gap">
    <div class="container">
        Section Title
        <div class="section-title text-center both-border mb-50">
            <span class="title-tag">Most Features</span>
            <h2 class="title">We Provide Most Exclusive <br> Service For Business</h2>
        </div>
        Services Boxes
        <div class="row service-boxes justify-content-center">
            <div class="col-lg-3 col-sm-6 col-10 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                <div class="service-box-three border-0">
                    <div class="icon">
                        <img src="assets/img/icons/01.png" alt="Icon">
                    </div>
                    <h3><a routerLink='/service-detail'>Creative Idea</a></h3>
                    <p>Sed perspicia unde omnis</p>
                    <a routerLink='/service-detail' class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="500ms">
                <div class="service-box-three border-0">
                    <div class="icon">
                        <img src="assets/img/icons/02.png" alt="Icon">
                    </div>
                    <h3><a routerLink='/service-detail'>Business Strategy</a></h3>
                    <p>Quis autem velrepres hend</p>
                    <a routerLink='/service-detail' class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                <div class="service-box-three border-0">
                    <div class="icon">
                        <img src="assets/img/icons/03.png" alt="Icon">
                    </div>
                    <h3><a routerLink='/service-detail'>Relationship</a></h3>
                    <p>Sed perspicia unde omnis</p>
                    <a routerLink='/service-detail' class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-10 wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="700ms">
                <div class="service-box-three border-0">
                    <div class="icon">
                        <img src="assets/img/icons/04.png" alt="Icon">
                    </div>
                    <h3><a routerLink='/service-detail'>Productivity</a></h3>
                    <p>Quis autem velrepres hend</p>
                    <a routerLink='/service-detail' class="service-link">
                        <i class="fal fa-long-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="line-one">
        <img src="assets/img/lines/12.png" alt="line-shape">
    </div>
    <div class="line-two">
        <img src="assets/img/lines/11.png" alt="line-shape">
    </div>
</section> -->
<!--====== Service Section End ======-->

<!--====== Advanced Tabs Section Start ======-->
<!-- <section class="advanced-tab section-gap">
    <div class="container">
        Tabs Buttons
        <div class="tab-buttons">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="active" id="nav-mission" data-toggle="tab" href="#tab-mission" role="tab">Our Mission &
                    Vision</a>
                <a id="nav-history" data-toggle="tab" href="#tab-history" role="tab">Company History</a>
                <a id="nav-business" data-toggle="tab" href="#tab-business" role="tab">Business Goals</a>
                <a id="nav-team" data-toggle="tab" href="#tab-team" role="tab">Team Member</a>
            </div>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="tab-mission" role="tabpanel">
                    <div class="tab-text-block left-image with-left-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="block-image">
                                    <img src="assets/img/tab-block.jpg" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10">
                                <div class="block-text">
                                    <h2 class="title">Professional Business Guidance Agency</h2>
                                    <p>
                                        Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                        doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                        veritatis et quasi
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            There are many variations of passages of LoreIpsum available, but the
                                            majority have suffered
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            It uses a dictionary of over 200 Latin wor combined with a handful of
                                            model sentence structure
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                            looked up one more
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-history" role="tabpanel">
                    <div class="tab-text-block right-image with-right-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                <div class="block-text">
                                    <h2 class="title">Professional Business Guidance Agency</h2>
                                    <p>
                                        Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                        doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                        veritatis et quasi
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            There are many variations of passages of LoreIpsum available, but the
                                            majority have suffered
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            It uses a dictionary of over 200 Latin wor combined with a handful of
                                            model sentence structure
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                            looked up one more
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                <div class="block-image">
                                    <img src="assets/img/tab-block.jpg" alt="Image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-business" role="tabpanel">
                    <div class="tab-text-block left-image with-left-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="block-image">
                                    <img src="assets/img/tab-block.jpg" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10">
                                <div class="block-text">
                                    <h2 class="title">Professional Business Guidance Agency</h2>
                                    <p>
                                        Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                        doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                        veritatis et quasi
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            There are many variations of passages of LoreIpsum available, but the
                                            majority have suffered
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            It uses a dictionary of over 200 Latin wor combined with a handful of
                                            model sentence structure
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                            looked up one more
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-team" role="tabpanel">
                    <div class="tab-text-block right-image with-right-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                <div class="block-text">
                                    <h2 class="title">Professional Business Guidance Agency</h2>
                                    <p>
                                        Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                        doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                        veritatis et quasi
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            There are many variations of passages of LoreIpsum available, but the
                                            majority have suffered
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            It uses a dictionary of over 200 Latin wor combined with a handful of
                                            model sentence structure
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                            looked up one more
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                <div class="block-image">
                                    <img src="assets/img/tab-block.jpg" alt="Image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--====== Advanced Tabs Section End ======-->

<!--====== Video Start ======-->
<!-- <section class="video-section-two bg-img-c" style="background-image: url(assets/img/video-bg/video-2.jpg);">
    <div class="container">
        <div class="row align-content-center justify-content-center">
            <div class="col-lg-10">
                <div class="video-cont text-center">
                    <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="play-btn popup-video wow fadeInLeft"
                        data-wow-duration="1500ms" data-wow-delay="400ms"><i class="fas fa-play"></i></a>
                    <h2>
                        Watch Our Latest Videos For Better Innovative Business
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <div class="line-shape-one">
        <img src="assets/img/lines/12.png" alt="Line">
    </div>
    <div class="line-shape-two">
        <img src="assets/img/lines/11.png" alt="Line">
    </div>
</section> -->
<!--====== Video end ======-->

<!--====== Testimonials Section start ======-->
<!-- <section class="testimonial-section-three no-bg">
    <div class="container">
        <div class="row justify-content-center no-gutters">
            <div class="col-lg-10">
                <div class="testimonial-items" id="testimonialSliderThree">
                    <div class="testimonial-item text-center">
                        <div class="author-thumb">
                            <img src="assets/img/testimonial/author-9.png" alt="image">
                        </div>

                        <div class="content">
                            <p>
                                <span class="quote-top">
                                    <i class="fal fa-quote-left"></i>
                                </span>
                                Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                <span class="quote-bottom">
                                    <i class="fal fa-quote-right"></i>
                                </span>
                            </p>
                        </div>

                        <div class="author">
                            <h4>Richard E. Harrisona</h4>
                            <span>CEO & Founder</span>
                        </div>
                    </div>
                    <div class="testimonial-item text-center">
                        <div class="author-thumb">
                            <img src="assets/img/testimonial/author-9.png" alt="image">
                        </div>

                        <div class="content">
                            <p>
                                <span class="quote-top">
                                    <i class="fal fa-quote-left"></i>
                                </span>
                                Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                <span class="quote-bottom">
                                    <i class="fal fa-quote-right"></i>
                                </span>
                            </p>
                        </div>

                        <div class="author">
                            <h4>Richard E. Harrisona</h4>
                            <span>CEO & Founder</span>
                        </div>
                    </div>
                    <div class="testimonial-item text-center">
                        <div class="author-thumb">
                            <img src="assets/img/testimonial/author-9.png" alt="image">
                        </div>

                        <div class="content">
                            <p>
                                <span class="quote-top">
                                    <i class="fal fa-quote-left"></i>
                                </span>
                                Sedut perspiciatis unde omniiste natus errorsit voluptatem accusant doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae ab ilinventore veritatis et quasi
                                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                <span class="quote-bottom">
                                    <i class="fal fa-quote-right"></i>
                                </span>
                            </p>
                        </div>

                        <div class="author">
                            <h4>Richard E. Harrisona</h4>
                            <span>CEO & Founder</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--====== Testimonials Section end ======-->

<!--====== Client Area Start ======-->
<!-- <section class="client-section">
    <div class="container">
        <div class="client-slider section-gap line-top">
            <div class="row align-items-center justify-content-between" id="clientSlider">
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/01.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/02.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/03.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/04.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/05.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/01.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/02.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/03.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/04.png" alt="">
                    </a>
                </div>
                <div class="col">
                    <a routerLink='/about-us' class="client-img d-block text-center">
                        <img src="assets/img/clients/05.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--====== Client Area End ======-->
<app-footer1></app-footer1>