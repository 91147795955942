<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(../../../../assets/img/productsBGArtboard\ 1.png);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title"><a style="color: #f2576d;">P</a>roducts</h1>
                <ul>
                    <p
					style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
					Make your life a masterpiece. Imagine no limitations on what you can be, have or do.</p>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Shop Section Start ======-->
    <section class="shop-page-wrap section-gap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 order-1">
                    <div class="row shop-top-bar justify-content-between">
                        <!-- <div class="col-lg-3 col-6 col-tiny-12">
                            <div class="product-search">
                                <form action="#">
                                    <input type="search" placeholder="Search Keywords">
                                    <button type="button"><i class="far fa-search"></i></button>
                                </form>
                            </div>
                        </div> -->
                        <!-- <div class="col-lg-2 col-6 col-tiny-12">
                            <div class="product-shorting">
                                <select>
                                    <option value="0" selected>Short by</option>
                                    <option value="1">Deafult</option>
                                    <option value="2">By Name</option>
                                    <option value="3">By Price</option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-10 order-3 order-lg-2">
                    <app-shop-sidebar></app-shop-sidebar>
                </div> -->
                <div class="col-lg-9 order-2 order-lg-2">
                    <div class="product-loop row">
                        <div *ngFor="let item of Products" class="col-lg-4 col-sm-6">
                            <div class="single-product">
                                <div class="product-img">
                                    <img src="{{item.image}}" alt="image">
                                    <!-- <div class="product-action">
                                        <a class="color"><i class="far fa-shopping-cart"></i></a>
                                        <a class="color"><i class="far fa-heart"></i></a>
                                        <a class="color"><i class="far fa-eye"></i></a>
                                    </div> -->
                                </div>
                                <div class="product-desc">
                                    <!-- <ul class="rating">
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fas fa-star"></i>
                                        </li>
                                    </ul> -->
                                    <h5 class="title"><a>{{item.title}}</a></h5>
                                    <span class="price">{{item.price}}</span>
                                </div>
                            </div>
                        </div>
                       
                           
                    </div>
                    <!-- <div class="pagination-wrap text-center">
                        <ul>
                            <li><a class="color"><i class="far fa-angle-left"></i></a></li>
                            <li class="active"><a routerLink='/shop'>01</a></li>
                            <li><a routerLink='/shop'>02</a></li>
                            <li><a routerLink='/shop'>03</a></li>
                            <li><a routerLink='/shop'><i class="far fa-angle-right"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
    <!--====== Shop Section End ======-->
    <app-footer1></app-footer1>
