<app-header1></app-header1>

<!--====== Breadcrumb part Start ======-->
<section class="breadcrumb-section bg-img-c" style="background-image: url(../../../../assets/img/contribute1Artboard\ 1.png);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1 class="page-title"> <a style="color: #f2576d;">C</a>ontribute</h1>
            <ul>
                <p style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">You have brilliance in you, your contribution is valuable, and the art you create is precious.</p>
            </ul>
        </div>
    </div>
    <div class="breadcrumb-shapes">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
    </div>
</section>
<!--====== Breadcrumb part End ======-->

<!--====== Latest Post Start ======-->
<section class="latest-post-section section-gap">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-8 col-10 col-tiny-12">
                <div class="section-title left-border ">
                    <span class="title-tag">Look In to</span>
                    <h2 class="title">How You Can Help</h2>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-4 col-10 col-tiny-12">
					<div class="text-md-right mt-30 mt-md-0">
						<a routerLink='/blog-grid' class="main-btn">View All News</a>
					</div>
				</div> -->
        </div>
        <div class="latest-post-loop row mt-50 justify-content-center">
            <div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms"
                data-wow-delay="400ms">
                <div class="latest-post-box">
                    <div class="post-thumb-wrap">
                        <div class="post-thumb bg-img-c" style="background-image: url(../../../../assets/img/illustration/volentierArtboard\ 1.png);">
                        </div>
                    </div>
                    <div class="post-desc post-desc-box">
                        <!-- <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span> -->
                        <h3 class="title">
                            <a class="color" >
                                Be a Volunteer
                            </a>
                        </h3>
                        <p>
                            Join Real Life team as an “ANGEL” to help families and make happier lifestyle with
                            celebrations of their life moments
                        </p>
                        <!-- <a  class="post-link">
								Learn More <i class="far fa-long-arrow-right"></i>
							</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInUp" data-wow-duration="1500ms"
            data-wow-delay="400ms">
            <div class="latest-post-box">
                <div class="post-thumb-wrap">
                    <div class="post-thumb bg-img-c" style="background-image: url(../../../../assets/img/illustration/DonateArtboard\ 1.png);">
                    </div>
                </div>
                <div class="post-desc">
                    <!-- <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span> -->
                    <h3 class="title">
                        <a  class="color" >
                            Donate
                        </a>
                    </h3>
                    <p>
                        Donate Real Life club with gifts, free services, vouchers or funds. Please Contact Jasmine
                        by <a class="color">info@reallife.lk</a> and <a class="color">rlsrilanka@gmail.com</a>
                    </p>
                    <!-- <a  class="post-link">
                Learn More <i class="far fa-long-arrow-right"></i>
            </a> -->
                </div>
            </div>
        </div>
          
            <div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInRight" data-wow-duration="1500ms"
                data-wow-delay="400ms">
                <div class="latest-post-box">
                    <div class="post-thumb-wrap">
                        <div class="post-thumb bg-img-c" style="background-image: url(../../../../assets/img/illustration/Event\ planing\ 1Artboard\ 1.png);">
                        </div>
                    </div>
                    <div class="post-desc post-desc-box">
                        <!-- <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span> -->
                        <h3 class="title">
                            <a class="color" >
                                Event Partners
                            </a>
                        </h3>
                        <p>
                            Become Real Life event partners to plan, organize programs/events for Real Life families.
                        </p>
                        <!-- <a  class="post-link">
                        Learn More <i class="far fa-long-arrow-right"></i>
                    </a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInUp" data-wow-duration="1500ms"
            data-wow-delay="400ms">
            <div class="latest-post-box">
                <div class="post-thumb-wrap">
                    <div class="post-thumb bg-img-c" style="background-image: url(../../../../assets/img/illustration/AgentArtboard\ 1.png);">
                    </div>
                </div>
                <div class="post-desc ">
                    <!-- <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span> -->
                    <h3 class="title">
                        <a class="color" >
                            Be a Mentor/ Agent
                        </a>
                    </h3>
                    <p>
                        Join Real Life team as an “AGENT” to introduce and recommend our products and services to
                        people for happier lifestyle. You can also become a “Mentor” to Real Life families and
                        conduct Real Life programs/ events.
                    </p>
                    <!-- <a  class="post-link">
                        Learn More <i class="far fa-long-arrow-right"></i>
                    </a> -->
                </div>
            </div>
        </div>
           
            <div class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="400ms">
                <div class="latest-post-box">
                    <div class="post-thumb-wrap">
                        <div class="post-thumb bg-img-c" style="background-image: url(../../../../assets/img/illustration/CorporateArtboard\ 1.png);">
                        </div>
                    </div>
                    <div class="post-desc">
                        <!-- <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span> -->
                        <h3 class="title">
                            <a class="color" >
                                Corporate Support
                            </a>
                        </h3>
                        <p>
                            Become financial investor for our programs / projects / events. We conduct family
                            brainstorming events, health camps, and family festivals and children programs.

                            Align your company CSR plan with us!
                        </p>
                        <!-- <a  class="post-link">
                Learn More <i class="far fa-long-arrow-right"></i>
            </a> -->
                    </div>
                </div>
            </div>
            <div hidden class="col-lg-4 col-md-6 col-10 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms"
                data-wow-delay="400ms">
                <div class="latest-post-box">
                    <div class="post-thumb-wrap">
                        <div class="post-thumb bg-img-c" style="background-image: url(assets/img/latest-post/01.jpg);">
                        </div>
                    </div>
                    <div class="post-desc">
                        <!-- <span class="post-date"><i class="far fa-calendar-alt"></i>25 Aug 2020</span> -->
                        <h3 class="title">
                            <a class="color" >
                                How Performance Visiblety With GitLab CI And Hood
                            </a>
                        </h3>
                        <p>
                            Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam
                            raperiaeaqupsa quae
                        </p>
                        <!-- <a routerLink='/blog-detail' class="post-link">
            Learn More <i class="far fa-long-arrow-right"></i>
        </a> -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!--====== Latest Post Start ======-->
<app-footer1></app-footer1>