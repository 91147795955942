<app-header1></app-header1>

<!--====== Breadcrumb part Start ======-->
<section class="breadcrumb-section bg-img-c" style="background-image: url(../../../../assets/img/realLifeShipArtboard\ 1.png);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1 class="page-title"> <a style="color:#f2576d;">R</a>eal-Life-Ship</h1>
            <ul>
                
                <p
					style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
					Instead of letting your hardships and failures discourage or exhaust you, let them inspire you.</p>
            </ul>
        </div>
    </div>
    <div class="breadcrumb-shapes">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
    </div>
</section>
<!--====== Breadcrumb part End ======-->
<!--====== Advanced Tabs Section Start ======-->
<section class="advanced-tab section-gap">
    <div class="container">
        <!-- Tabs Buttons -->
        <div class="tab-buttons">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="active" id="nav-mission" data-toggle="tab" href="#tab-about"
                    role="tab">&nbsp;&nbsp;&nbsp;&nbsp;About&nbsp;&nbsp;&nbsp;&nbsp;</a>
                <a id="nav-history" data-toggle="tab" href="#tab-pricing" role="tab">&nbsp;Pricing&nbsp;</a>
                <a id="nav-business" data-toggle="tab" href="#tab-how-to-apply" role="tab">How to apply</a>
                <!-- <a id="nav-team" data-toggle="tab" href="#tab-team" role="tab">Team Member</a> -->
            </div>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="tab-about" role="tabpanel">
                    <div class="tab-text-block left-image with-left-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="block-image">
                                    <img src="../../../../assets/img/illustration/About RLShipArtboard 1.png" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10">
                                <div class="block-text">
                                    <h2 class="title">About</h2>
                                    <p>
                                        The club at REAL LIFE is a dynamic social club in SRI LANKA to support families
                                        for event planning and organizing of birthdays, wedding anniversary,
                                        achievements etc…
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            A creative, innovative and cultural space that’s been carefully curated for
                                            the
                                            continually curious and those motivated by social change for happier life
                                            style
                                            with strengthening relationships.
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Real Life-ship fees and the suggested donation facilitate the work of social
                                            entrepreneurship with employment, academy, supporting families in Sri Lanka
                                            to
                                            celebrate life movements.
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            We create life style change of Sri Lankan families towards strong
                                            relationships
                                            to care and protect children and enrich of happier life in REAL…
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            If you have any questions or would like to get in touch to discuss Real
                                            Life-ship in more detail then please contact our Real Life-ship Team,
                                            Jasmine by
                                            email info@reallife.lk.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-pricing" role="tabpanel">
                    <div class="tab-text-block right-image with-right-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                <div class="block-text">
                                    <h2 class="title">Pricing</h2>
                                    <p>
                                        All individual memberships are inclusive of VAT and subject to a one off one
                                        time joining fee of 1000LKR.
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            kids under 18 years subscription free one time joining fee 500LKR.
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            The suggested donation supports our work, enabling the charity to achieve
                                            its
                                            vision. Your donation will play a vital part in the success of our
                                            employment
                                            academy and research participants.
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Kids under 14 are will have free Real Life-ship with their family.
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Corporate Real Life-ship Packages start at 3000LKR per person with a 1000LKR
                                            joining fee for the organisation.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                <div class="block-image">
                                    <img src="../../../../assets/img/illustration/pricingArtboard 1.png" alt="Image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab-how-to-apply" role="tabpanel">
                    <div class="tab-text-block left-image with-left-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10">
                                <div class="block-image">
                                    <img src="../../../../assets/img/illustration/how to applyArtboard 1.png" alt="Image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10">
                                <div class="block-text">
                                    <h2 class="title">How To Apply</h2>
                                    <p>
                                        To apply for individual memberships prospective members need to complete our
                                        online application form below. Those applicants completed online applications to
                                        become Real Life-ship member, will be notified via email. There will be maximum
                                        of 30 days trial to the real life -Ship.
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Application does not guarantee acceptance of Real Life-ship unless the paid
                                            for selected, Real Life-ship level. If you are interested in real life
                                            -Ship please contact the Real Life-ship Team, Jasmine by email
                                            <a>info@reallife.lk</a>. 
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="tab-pane fade" id="tab-team" role="tabpanel">
                    <div class="tab-text-block right-image with-right-circle">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                                <div class="block-text">
                                    <h2 class="title">Professional Business Guidance Agency</h2>
                                    <p>
                                        Sedut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                        doloremque laudantium, totam raperiaeaque ipsa quae ab illo inventore
                                        veritatis et quasi
                                    </p>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            There are many variations of passages of LoreIpsum available, but the
                                            majority have suffered
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            It uses a dictionary of over 200 Latin wor combined with a handful of
                                            model sentence structure
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i>
                                            Richard McClintock, a Latin profe hampden-sydney College in Virginia,
                                            looked up one more
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                                <div class="block-image">
                                    <img src="assets/img/tab-block.jpg" alt="Image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!--====== Advanced Tabs Section End ======-->
<app-footer1></app-footer1>