<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
<div class="breadcrumb-section bg-img-c" style="background-image: url(../../../../assets/img/event\ 3Artboard\ 1.png);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1 class="page-title"> <a style="color: #f2576d;">E</a>vents</h1>
            <ul>
                <p
                    style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
                    Everyday can be the beginning of a new life.</p>
            </ul>
        </div>
    </div>
    <div class="breadcrumb-shapes">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
    </div>
</div>
<!--====== Breadcrumb part End ======-->

<!--====== Blog Section Start ======-->
<section class="blog-section section-gap-top">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <!-- Blog loop(Standard) -->
                <div class="blog-loop standard-blog row">
                    <!-- Single Post -->
                    <div class="col-12">
                        <div class="single-post-box">
                            <h3 class="with-check"><i class="far fa-check-circle"></i> Networking And Mock Tolks</h3>
                            <br>
                            <div class="post-thumb">
                                <img src="../../../../assets/img/E1Artboard 1.png" alt="Image">
                            </div>
                            <div class="post-meta">
                                <!-- <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul> -->
                            </div>

                        </div>
                    </div>
                    <!-- Single Post -->
                    <div class="col-12">
                        <h3 class="with-check"><i class="far fa-check-circle"></i> Honorary Ambassador Award Discussion
                            2019</h3>
                        <br>
                        <div class="single-post-box">
                            <div class="post-thumb">
                                <img src="../../../../assets/img/E2Artboard 1.png" alt="Image">
                            </div>
                            <div class="post-meta">
                                <!-- <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul> -->
                            </div>

                        </div>
                    </div>
                    <!-- Single Post -->

                    <br>

                    <!-- Blog Details start -->
                    <div class="post-details-wrap">
                        <!-- <div class="post-thumb">
									<img src="assets/img/blog/12.jpg" alt="Image">
								</div> -->
                        <!-- <div class="post-meta">
									<ul>
										<li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
										<li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
										<li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
									</ul>
								</div> -->
                        <div class="post-content">
                            <!-- <h2 class="title">
                                Real Life Story…
                            </h2> -->


                            <!-- <blockquote>
					Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
					<span class="author">Bailey Dobson</span>
				</blockquote> -->
                            <br>
                            <h3 class="with-check"><i class="far fa-check-circle"></i> REAL LIFE Organic Garden</h3>
                            <br>
                            <div class="post-thumb">
                                <img src="../../../../assets/img/project/rl event 1.jpg" alt="Image">
                            </div>

                            <p class="fl">
                                We ensure to provide 100% Organic and natural fresh produce to urban living and expand
                                island wide delivery for the demand in future. We enhance our capacity by introducing
                                urban organic gardening and community gardening concepts to adopt healthy and self
                                sustain lifestyle.
                            </p>


                        </div>

                        <!-- <div class="post-thumb ctr">
                            <img src="../../../../assets/img/team/RL goals.jpg" alt="Image">
                        </div> -->
                        <!-- <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
				<div class="post-tag">
					<ul>
						<li class="title">Popular Tags :</li>
						<li><a routerLink='/blog-detail'>IT Consulting,</a></li>
						<li><a routerLink='/blog-detail'>Business,</a></li>
						<li><a routerLink='/blog-detail'>Corporate,</a></li>
					</ul>
				</div>
				<div class="post-share">
					<ul>
						<li class="title">Share :</li>
						<li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
						<li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
						<li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
						<li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
					</ul>
				</div>
			</div> -->
                    </div>
                    <!-- Blog Details end -->

                    <br>

                    <!-- Blog Details start -->
                    <div class="post-details-wrap">
                        <!-- <div class="post-thumb">
                                                        <img src="assets/img/blog/12.jpg" alt="Image">
                                                    </div> -->
                        <!-- <div class="post-meta">
                                                        <ul>
                                                            <li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
                                                            <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
                                                            <li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
                                                        </ul>
                                                    </div> -->
                        <div class="post-content">
                            <!-- <h2 class="title">
                                                    Real Life Story…
                                                </h2> -->


                            <!-- <blockquote>
                                        Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
                                        <span class="author">Bailey Dobson</span>
                                    </blockquote> -->
                            <br>
                            <h3 class="with-check"><i class="far fa-check-circle"></i> Creative Entrepreneurship Program
                            </h3>
                            <br>
                            <div class="post-thumb">
                                <img src="../../../../assets/img/project/rl event 2.jpg" alt="Image">
                            </div>

                            <p class="fl">
                                Real Life volunteered to provide psychological support services offering mentoring &
                                capacity development through emotional & art therapy by Origami designed recycle paper
                                arts to build lives of Outpatients of Cancer in a sustainable social subsidiary system.
                            </p>

                            <p class="fl pt-1">
                                We have global brand <a style="color: black; font-weight: 900;">O'girl&reg;</a> made of
                                100% biodegradable recycle paper.
                            </p>

                            <p class="fl pt-1">
                                We promote creative entrepreneurship by providing raw materials and training by capacity
                                development to the selected participants and buy those products to resell to our
                                clientele through our online store. &nbsp;<a href="https://www.reallife.lk/"
                                    target="_blanck">www.reallife.com</a>
                            </p>

                            <p class="fl pt-1">
                                Thus we create outpatients of Cancer as Co-creators of the brand <a
                                    style="color: black; font-weight: 900;">O'girl&reg;</a> made of
                                100% biodegradable recycle paper.
                            </p>

                            <p class="fl pt-1 pb-2">
                                This program is ongoing from May 2019 onward and developed into next group of
                                beneficiaries of rural communities in future.
                            </p>

                            <div class="post-thumb">
                                <img src="../../../../assets/img/project/rl event 3.jpg" alt="Image">
                            </div>


                        </div>

                        <!-- <div class="post-thumb ctr">
                                                <img src="../../../../assets/img/team/RL goals.jpg" alt="Image">
                                            </div> -->
                        <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
                            <!-- <div class="post-tag">
                                        <ul>
                                            <li class="title">Popular Tags :</li>
                                            <li><a routerLink='/blog-detail'>IT Consulting,</a></li>
                                            <li><a routerLink='/blog-detail'>Business,</a></li>
                                            <li><a routerLink='/blog-detail'>Corporate,</a></li>
                                        </ul>
                                    </div> -->
                            <div class="post-share">
                                <ul>
                                    <!-- <li class="title">Share :</li> -->
                                    <li><a class="color" href='https://www.facebook.com/ecofriendlybrand/'
                                            target="_blanck"><i style="font-size: xx-large;"
                                                class="color fab fa-facebook-f"></i>&nbsp; Facobook Page</a></li>
                                    <!-- <li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Blog Details end -->

                    <br>
                    <!-- Blog Details start -->
                    <div class="post-details-wrap">
                        <!-- <div class="post-thumb">
                                                        <img src="assets/img/blog/12.jpg" alt="Image">
                                                    </div> -->
                        <!-- <div class="post-meta">
                                                        <ul>
                                                            <li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
                                                            <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
                                                            <li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
                                                        </ul>
                                                    </div> -->
                        <div class="post-content">
                            <!-- <h2 class="title">
                                                    Real Life Story…
                                                </h2> -->


                            <!-- <blockquote>
                                        Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
                                        <span class="author">Bailey Dobson</span>
                                    </blockquote> -->
                            <br>
                            <h3 class="with-check"><i class="far fa-check-circle"></i> Life coaching programs
                            </h3>
                            <br>
                            <div class="post-thumb">
                                <img src="../../../../assets/img/project/rl event 4.png" alt="Image">
                            </div>

                            <p class="fl">
                                We provide free awareness for 1:1 and group sessions to urban and rural communities to
                                adopt sustainable lifestyle via Radio/ TV and social media programs and YouTube channels
                                though Real Life network.
                            </p>

                        </div>

                        <!-- <div class="post-thumb ctr">
                                                <img src="../../../../assets/img/team/RL goals.jpg" alt="Image">
                                            </div> -->
                        <!-- <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
                                    <div class="post-tag">
                                        <ul>
                                            <li class="title">Popular Tags :</li>
                                            <li><a routerLink='/blog-detail'>IT Consulting,</a></li>
                                            <li><a routerLink='/blog-detail'>Business,</a></li>
                                            <li><a routerLink='/blog-detail'>Corporate,</a></li>
                                        </ul>
                                    </div>
                                    <div class="post-share">
                                        <ul>
                                            <li class="title">Share :</li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div> -->
                    </div>
                    <!-- Blog Details end -->

                    <br>

                    <!-- Blog Details start -->
                    <div class="post-details-wrap">
                        <!-- <div class="post-thumb">
                                                                            <img src="assets/img/blog/12.jpg" alt="Image">
                                                                        </div> -->
                        <!-- <div class="post-meta">
                                                                            <ul>
                                                                                <li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
                                                                                <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
                                                                                <li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
                                                                            </ul>
                                                                        </div> -->
                        <div class="post-content">
                            <!-- <h2 class="title">
                                                                        Real Life Story…
                                                                    </h2> -->


                            <!-- <blockquote>
                                                            Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
                                                            <span class="author">Bailey Dobson</span>
                                                        </blockquote> -->
                            <br>
                            <h3 class="with-check"><i class="far fa-check-circle"></i> Knowledge Sharing and Edutainment
                                program
                            </h3>
                            <br>
                            <div class="post-thumb">
                                <img src="../../../../assets/img/project/rl event 5.png" alt="Image">
                            </div>

                            <p class="fl pb-1">
                                Real Life organize “Inspire Yourself" Knowledge forum every year and share knowledge and
                                provide lifelong learning experience. The forum is targeted at interested individuals to
                                find meaningful life to perform better in education, career, entrepreneurship and
                                building the professional network.
                            </p>

                            <p class="fl pb-1">
                                The purpose of the knowledge forum is to focus on lifelong learning of improving
                                knowledge and experience in life that would benefit the individuals, startups and serial
                                entrepreneurs to gain knowledge, groom and support to uplift them in their own career
                                paths.
                            </p>

                            <p class="fl pb-1">
                                It is aimed for the betterment of the skills of youth, professionals and partners in the
                                SME sector and budding entrepreneurs in Sri Lanka.
                            </p>

                        </div>

                        <!-- <div class="post-thumb ctr">
                                                                    <img src="../../../../assets/img/team/RL goals.jpg" alt="Image">
                                                                </div> -->
                        <!-- <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
                                                        <div class="post-tag">
                                                            <ul>
                                                                <li class="title">Popular Tags :</li>
                                                                <li><a routerLink='/blog-detail'>IT Consulting,</a></li>
                                                                <li><a routerLink='/blog-detail'>Business,</a></li>
                                                                <li><a routerLink='/blog-detail'>Corporate,</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="post-share">
                                                            <ul>
                                                                <li class="title">Share :</li>
                                                                <li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
                                                                <li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
                                                                <li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
                                                                <li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
                                                            </ul>
                                                        </div>
                                                    </div> -->
                    </div>
                    <!-- Blog Details end -->

                    <!-- Blog Details start -->
                    <div class="post-details-wrap">
                        <!-- <div class="post-thumb">
                                                        <img src="assets/img/blog/12.jpg" alt="Image">
                                                    </div> -->
                        <!-- <div class="post-meta">
                                                        <ul>
                                                            <li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
                                                            <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
                                                            <li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
                                                        </ul>
                                                    </div> -->
                        <div class="post-content">
                            <!-- <h2 class="title">
                                                    Real Life Story…
                                                </h2> -->


                            <!-- <blockquote>
                                        Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
                                        <span class="author">Bailey Dobson</span>
                                    </blockquote> -->
                            <br>
                            <h3 class="with-check"><i class="far fa-check-circle"></i> Sustainable Events/ Wedding
                                solutions (Upcoming)
                            </h3>
                            <br>
                            <div class="post-thumb">
                                <img src="../../../../assets/img/project/rl event 6.png" alt="Image">
                            </div>

                            <p class="fl pb-1">
                                Real Life provides sustainable event/ wedding solutions to manage resources, maintain a
                                flexible budget and utilize environmental friendly strategies to fulfill their event/
                                wedding themes and make it a big difference to the community, society and the
                                environment.
                            </p>

                            <p class="fl pb-1">
                                Register with us in early Dec 2020 and get 10% off at &nbsp;<a class="color" href="https://slwedding.lk/" target="_blanck">www.slwedding.lk </a> 
                            </p>

                        </div>
                        <br><br>

                        <!-- <div class="post-thumb ctr">
                                                <img src="../../../../assets/img/team/RL goals.jpg" alt="Image">
                                            </div> -->
                        <!-- <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
                                    <div class="post-tag">
                                        <ul>
                                            <li class="title">Popular Tags :</li>
                                            <li><a routerLink='/blog-detail'>IT Consulting,</a></li>
                                            <li><a routerLink='/blog-detail'>Business,</a></li>
                                            <li><a routerLink='/blog-detail'>Corporate,</a></li>
                                        </ul>
                                    </div>
                                    <div class="post-share">
                                        <ul>
                                            <li class="title">Share :</li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
                                            <li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div> -->
                    </div>
                    <!-- Blog Details end -->


                    <!-- <div class="col-12">
                            <div class="single-post-box quote-post">
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Visual Design Language The Building Blocks Of Design Web Design Trend
                                            Becomes
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div> -->
                    <!-- Single Post -->
                    <!-- <div class="col-12">
                            <div class="single-post-box video-post">
                                <div class="post-video">
                                    <img src="assets/img/blog/03.jpg" alt="Image">
                                    <a href="https://www.youtube.com/watch?v=fEErySYqItI" class="popup-video">
                                        <i class="fas fa-play"></i>
                                    </a>
                                </div>
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            How Increase Mobile Conversions With Category Page DesignTrend Becomes
                                            Typography
                                        </a>
                                    </h3>
                                    <p>
                                        But must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pain was born and will give you a complete account of the system, and
                                        expound the actual teachings of the great explorer of the truth, the
                                        master-builder of human happiness. No one rejects, dislikes, or avoids pleasure
                                        itse.
                                    </p>
                                    <a routerLink='/blog-detail' class="main-btn">Learn More</a>
                                </div>
                            </div>
                        </div> -->
                    <!-- <div class="col-12">
                            <div class="single-post-box no-thumb">
                                <div class="post-meta">
                                    <ul>
                                        <li><i class="far fa-user"></i><a routerLink='/blog-standard'>Nichel Jhon</a></li>
                                        <li><i class="far fa-calendar-alt"></i><a routerLink='/blog-standard'>25 Aug 2020</a></li>
                                        <li><i class="far fa-comments"></i><a routerLink='/blog-standard'>Comments (05)</a></li>
                                    </ul>
                                </div>
                                <div class="post-content">
                                    <h3 class="title">
                                        <a routerLink='/blog-detail'>
                                            Inspired Design Decisions With Otto Storch When Idea Copy Art And Typography
                                        </a>
                                    </h3>
                                    <p>
                                        But must explain to you how all this mistaken idea of denouncing pleasure and
                                        praising pain born and give you a complete account of the system, and expound
                                        the actual teachings of the great explorer of the the
                                        master-builder of human happiness. No one rejects,
                                    </p>
                                    <a routerLink='/blog-detail' class="main-btn">Learn More</a>
                                </div>
                            </div>
                        </div> -->
                </div>
                <!-- Pagination -->
                <!-- <div class="pagination-wrap">
                        <ul>
                            <li><a routerLink='/blog-standard'><i class="far fa-angle-left"></i></a></li>
                            <li class="active"><a routerLink='/blog-standard'>01</a></li>
                            <li><a routerLink='/blog-standard'>02</a></li>
                            <li><a routerLink='/blog-standard'>03</a></li>
                            <li><a routerLink='/blog-standard'><i class="far fa-angle-right"></i></a></li>
                        </ul>
                    </div> -->
            </div>
            <!-- <div class="col-lg-4 col-md-8">
                    sidebar
                    <app-blog-sidebar></app-blog-sidebar>
                </div> -->
        </div>
    </div>
</section>
<!--====== Blog Section End ======-->

<!--====== Client Area Start ======-->
<!-- <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/blog-standard' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
<!--====== Client Area End ======-->
<app-footer1></app-footer1>