<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
<section class="breadcrumb-section bg-img-c"
    style="background-image: url(../../../../assets/img/get\ In\ touch\ 2Artboard\ 1.png);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1 class="page-title"><a style="color: #f2576d;">G</a>et In Touch</h1>
            <ul>
                <p
                    style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
                    If you light a lamp for somebody, it will also brighten your path.</p>

            </ul>
        </div>
    </div>
    <div class="breadcrumb-shapes">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
    </div>
</section>
<!--====== Breadcrumb part End ======-->

<!--====== Contact Section start ======-->
<section class="contact-section contact-page section-gap-top">
    <div class="container">
        <div class="contact-info">
            <div class="row justify-content-center">
                <div class="col-lg-6 order-2 order-lg-1">
                    <div class="illustration-img text-center">
                        <img src="../../../../assets/img/get In touch 3Artboard 1.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                    <div class="contact-info-content">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Get In Touch</span>
                            <!-- <h2 class="title">Need Any Help For your Family</h2> -->
                        </div>
                        <p>
                            We all benefit by reaching out and helping others. Just remember this: no matter what
                            happens, you will always be secure in the fact knowing that you have strong and true family
                            to
                            assist you in your need.
                        </p>

                        <p> Nothing liberates our greatness like the desire to help, the desire to serve. Our helping
                            hands are always fresh and open for you. If you find a difficult situation we are here to
                            lend our hands.</p>

                        <ul>
                            <!-- <li class="phone">
                                    <a href="tel:+0123456789"><i class="far fa-phone"></i>+012 (345) 6789</a>
                                </li> -->
                                <li style="color: #f2576d;">Feel free to contact us </li>
                            <li><i style="color: #f2576d;" class="far fa-envelope-open"></i><a routerLink='/get-in-touch'>info@reallife.lk</a>
                            </li>
                            <!-- <li><i class="far fa-map-marker-alt"></i>Broklyn Street USA</li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="contact-form grey-bg">
                <div class="row no-gutters justify-content-center">
                    <div class="col-10">
                        <div class="section-title text-center mb-40">
                            <h2 class="title">Don’t Hesited To Contact Us</h2>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Your Full Name">
                                        <span class="icon"><i class="far fa-user-circle"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="email" placeholder="Your Email Address">
                                        <span class="icon"><i class="far fa-envelope-open"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" placeholder="Your Phone">
                                        <span class="icon"><i class="far fa-phone"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group textarea mb-30">
                                        <textarea placeholder="Write Message"></textarea>
                                        <span class="icon"><i class="far fa-pencil"></i></span>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="button" class="main-btn">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
    </div>

    <!-- <div class="container-fluid container-1600">
            <div class="contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63371.815297074514!2d79.82118589335941!3d6.921837369631892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae253d10f7a7003%3A0x320b2e4d32d3838d!2sColombo!5e0!3m2!1sen!2slk!4v1604554818681!5m2!1sen!2slk" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div> -->
</section>
<br><br>
<!--====== Contact Section start ======-->

<!--====== Client Area Start ======-->
<!-- <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
<!--====== Client Area End ======-->
<app-footer1></app-footer1>