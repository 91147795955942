<app-header1></app-header1>
<!--====== Breadcrumb part Start ======-->
    <section class="breadcrumb-section bg-img-c" style="background-image: url(../../../../assets/img/gallery\ 1Artboard\ 1.png);">
        <div class="container">
            <div class="breadcrumb-text">
                <h1 class="page-title"> <a style="color:#f2576d;">G</a>allery</h1>
                <ul>
                    
                    <p
					style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
					The best view comes after the hardest climb. Believe in yourself.</p>
                </ul>
            </div>
        </div>
        <div class="breadcrumb-shapes">
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
        </div>
    </section>
    <!--====== Breadcrumb part End ======-->

    <!--====== Project section Start ======-->
    <section class="project-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <ul class="project-nav project-isotope-filter">
                        <li data-filter="*" class="active"> All Project </li>
                        <li data-filter=".item-one"> Origami Envilops </li>
                        <li data-filter=".item-two"> Gifts </li>
                        <li data-filter=".item-three"> Greeting Cards </li>
                        <li data-filter=".item-four"> Envilops </li>
                        <li data-filter=".item-five"> Flowers </li>
                        <li data-filter=".item-six"> Bags </li>
                    </ul>
                </div>
            </div>

            <!-- Project Boxes -->
            <div class="row project-boxes project-isotope mt-60 justify-content-center">
                <div class="isotope-item col-lg-4 col-sm-6 item-one item-four">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro1Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-six item-three item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro2Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>How To Business</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-two item-five item-one">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro3Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Design Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-six item-one item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro4Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Research Strategy</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-three item-five item-three">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro5Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>IT Consultations</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-six item-four">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro6Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Business Monitor</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-four item-two item-five">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro7Artboard\ 1.png);"></div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Free Consulting</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-one item-three">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro8Artboard\ 1.png);">
                            </div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>Business Monitor</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="isotope-item col-lg-4 col-sm-6 item-five item-two">
                    <div class="project-box hover-style">
                        <div class="project-thumb">
                            <div class="thumb bg-img-c" style="background-image: url(../../../../assets/img/shop/pro9Artboard\ 1.png);">
                            </div>
                        </div>
                        <!-- <div class="project-desc text-center">
                            <h4><a routerLink='/portfolio-detail'>IT Consultations</a></h4>
                            <p>Join us for consultatoins</p>
                            <a routerLink='/portfolio-detail' class="project-link">
                                <i class="fal fa-long-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Project section End ======-->

    <!--====== CTA Start ======-->
    <section class="cta-aection">
        <div class="container">
            <div class="cta-wrap bg-img-c" style="background-image: url(assets/img/lines/13.png);">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="cta-content text-center">
                            <div class="section-title both-border mb-30">
                                <!-- <span class="title-tag">Get A Quote</span> -->
                                <h2 class="title">Hold hands with Real Life</h2>
                            </div>
                            <a routerLink='/get-in-touch' class="main-btn-s2 main-btn-3">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <br><br>
    <!--====== CTA Start ======-->

    <!--====== Client Area Start ======-->
    <!-- <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/portfolio-one' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!--====== Client Area End ======-->
    <app-footer1></app-footer1>
