import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-real-life-ship',
  templateUrl: './real-life-ship.component.html',
  styleUrls: ['./real-life-ship.component.css']
})
export class RealLifeShipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
