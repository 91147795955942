import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  constructor() { }






  ngOnInit(): void {
  }

  public Products = [
    { title: 'Gift Box', price: 'LKR :350.00 - LKR :850.00', image: '../../../../assets/img/shop/pro1Artboard 1.png' },
    { title: 'Origamin Designed Bag', price: 'LKR :50.00', image: '../../../../assets/img/shop/pro2Artboard 1.png' },
    { title: 'Red rose Bunch', price: 'LKR :350.00', image: '../../../../assets/img/shop/pro3Artboard 1.png' },
    { title: 'Gift Tags / Name Cards', price: 'LKR :150.00', image: '../../../../assets/img/shop/pro4Artboard 1.png' },
    { title: 'Simple Origami rose Greeting Card', price: 'LKR :250.00', image: '../../../../assets/img/shop/pro5Artboard 1.png' },
    { title: 'Three Origami roses Greeting card', price: 'LKR :300.00', image: '../../../../assets/img/shop/pro6Artboard 1.png' },
    { title: 'Origami Roses Decoration Set', price: 'LKR :250.00', image: '../../../../assets/img/shop/pro7Artboard 1.png' },
    { title: 'Origami Lotus Decoration Set', price: 'LKR :250.00', image: '../../../../assets/img/shop/pro8Artboard 1.png' },
    { title: 'Envilop', price: 'LKR :50.00', image: '../../../../assets/img/shop/pro9Artboard 1.png' },
    
    

  ];

}
