<app-header1></app-header1>

<!--====== Breadcrumb part Start ======-->
<section class="breadcrumb-section bg-img-c"
	style="background-image: url(../../../../assets/img/realLifeTeamArtboard\ 1.png);">
	<div class="container">
		<div class="breadcrumb-text">
			<h1 class="page-title"> <a style="color: #f2576d;">R</a>eal Life Team</h1>
			<ul>
				<p
					style="font-size: larger; font-family: 'Segoe UI', Arial, Helvetica, sans-serif !important;    color:  #f3f6f7;">
					It’s good to be blessed. It’s better to be a blessing.</p>

			</ul>
		</div>
	</div>
	<div class="breadcrumb-shapes">
		<div class="one"></div>
		<div class="two"></div>
		<div class="three"></div>
	</div>
</section>
<!--====== Breadcrumb part End ======-->
<!--====== Team Section Start ======-->
<section class="team-section section-gap">
	<div class="container ">
		<!-- Section Title -->
		<div class="section-title mb-40 both-border text-center">
			<span class="title-tag">Meet Our Team</span>
			<h2 class="title">We Have An Exclusive <br> Team Members</h2>
		</div>

		<!-- Team Boxes -->
		<!-- <div class="row team-members align-items-center justify-content-between" id="teamSliderOne">

			<div class="col-lg-6">
				<div class="team-member">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/founder.PNG" alt="TeamMember">
							<div class="social-icons">
								<a href="https://www.facebook.com/jasmine.nanayakkara" target="_blanck">
									<i class="fab fa-facebook-f"></i>
								</a>
								<a href="https://www.linkedin.com/in/jasminen/" target="_blanck">
									<i class="fab fa-linkedin-in"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-behance"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-instagram"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name"><a class="color">Eng. Jasmin Nanayakkara</a></h3>
						<span class="pro">Founder</span>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="team-member">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/alia2Artboard 1.png" alt="TeamMember">
							<div class="social-icons">
								<a href="https://www.facebook.com/aliawj" target="_blanck"><i class="fab fa-facebook-f"></i></a>
								<a href="https://www.linkedin.com/in/aliawhitneyjohnson/" target="_blanck"><i class="fab fa-linkedin-in"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-behance"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-instagram"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name"><a class="color">Alia Whitney-Johnson</a></h3>
						<span class="pro">First ever Honorary Ambassador 2018</span>
					</div>
				</div>
			</div>


		</div> -->
		<!--====== Member Details Start ======-->
		<section class="member-details-wrapper " style="padding-top: 40px;">
			<div class="container">
				<div class="member-details">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/founder.PNG" alt="TeamMember">
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name">Founder/ Director Eng. Jasmine Nanayakkara</h3>
						<span style="font-size: large; padding-top: 1.2rem;" class="pro">CEO , REAL LIFE TV</span>
						<p>Engineering Entrepreneur/ Social Activist / Life Coach / Mentor / Business Growth Strategist
						</p>


					</div>
					<div class="member-contact-info">
						<p>
							<a style="font-size: large; color: #f1455d;">Passion :</a> Research in Emotional
							Intelligence (EI) , Business Intelligence (BI) and
							sustainability
						</p>
						<p>
							<a style="font-size: large; color: #f1455d; ">Vision:</a> Share entrepreneurial expertise in
							developing strategies in creativity and innovation
							for technology towards sustainable development.
						</p>
						<!-- <h3 class="title">Get In Touch</h3> -->
						<span class="title-tag">Contact Me</span>
						<ul class="contact-info">
							<li><a href="https://www.facebook.com/jasmine.nanayakkara/" target="_blanck"><i
										style="font-size: x-large; color: #f1455d;" class="fab fa-facebook-f"></i></a>
								&nbsp;<a href="www.linkedin.com/in/jasminen" target="_blanck"><i
										style="font-size: x-large; color: #f1455d;" class="fab fa-linkedin-in"></i></a>
							</li>

						</ul>

					</div>
				</div>
			</div>
		</section>
		<!--====== Member Details End ======-->


		<!--====== Member Details Start ======-->
		<section class="member-details-wrapper " style="padding-top: 40px;">
			<div class="container">
				<div class="member-details">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/Ruby Subasinghe.jpg" alt="TeamMember">
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name">Director, Real Life Ruby Subasinghe</h3>
						<span style="font-size: large; padding-top: 1.2rem;" class="pro">Entrepreneur / Cosmetologist /
							Mother of two / Social Activist </span>

					</div>
					<div class="member-contact-info">
						<div>
							<p>
								<a style="font-size: large; color: #f1455d;">Passion :</a> Creating a beauty home in a
								cozy
								atmosphere while specialize in facial, Skin and hair treatments and Bridal make up. Care
								in
								giving and organize Non-Profit events and brand building.
							</p>
							<p>
								<a style="font-size: large; color: #f1455d;">Vision :</a> share values in Real Life and
								create family happiness and strengthening relationships.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--====== Member Details End ======-->

		<!--====== Member Details Start ======-->
		<section class="member-details-wrapper " style="padding-top: 40px;">
			<div class="container">
				<div class="member-details">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/mahesh Munaweera.jpg" alt="TeamMember">
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name">Director, Real Life Mahesh Munaweera</h3>
						<span style="font-size: large; padding-top: 1.2rem;" class="pro">Entrepreneur/ IT Specialist/
							Business Consultant / Social Activist </span>

					</div>
					<div class="member-contact-info">
						<div>
							<p>
								<a style="font-size: large; color: #f1455d;">Passion :</a> Stand in Professional service
								in IT, Management and hospitality sector.
								He works as an IT service lead in Guys & St. Thomas Foundation Trust, UK and member of
								the Ceylon Hotel School Graduate Association.

							</p>
							<p>
								<a style="font-size: large; color: #f1455d;">Vision :</a> Being Social, environment and
								technology responsible and create awareness in the community.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--====== Member Details End ======-->
		<br><br>
		<div class="section-title mb-40 both-border text-center">
			<span class="title-tag">HONORARY AMBASSADORS</span>
			<p>
				Real Life network of ambassadors, mentors, counselors and volunteers are making an innovative model to
				address the social change in Sri Lanka towards happier lifestyle and sustainable living by providing
				continuous education, mental health support and edutainment programs for corporate sector employees and
				families, social clubs/ association members and families and the rural community.
			</p>
			<br>
			<p>
				Real Life welcomes 'change makers' with passion of making social impact and outcome based social and
				environmental projects.
			</p>
		</div>

		<!--====== Member Details Start ======-->
		<section class="member-details-wrapper " style="padding-top: 40px;">
			<div class="container">
				<div class="member-details">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/alia2Artboard 1.png" alt="TeamMember">
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name">Alia Whitney-Johnson</h3>
						<span style="font-size: large; padding-top: 1.2rem;" class="pro">First ever Honorary Ambassador
							2018</span>
						<p>
							Alia Whitney-Johnson (North Carolina & St. John’s 2009) is a consultant at McKinsey &
							Company and the Founder of Emerge Global, a non-profit that empowers teenage survivors of
							sexual abuse in Sri Lanka. She has been recognized for her work in Sri Lanka as a Sauvé
							Scholar, a YouthActionNet Fellow, a Truman Scholar, and as one of Glamour’s Top Ten College
							women in 2007.
						</p>
					</div>
					<div class="member-contact-info">
						<!-- <h3 class="title">Get In Touch</h3> -->
						<span class="title-tag">Contact Me</span>
						<ul class="contact-info">
							<li><a href="https://www.facebook.com/aliawj" target="_blanck"><i
										style="font-size: x-large; color: #f1455d;" class="fab fa-facebook-f"></i></a>
								&nbsp; <a href="https://www.linkedin.com/in/aliawhitneyjohnson/" target="_blanck"><i
										style="font-size: x-large; color: #f1455d;" class="fab fa-linkedin-in"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<!--====== Member Details End ======-->

		<!--====== Member Details Start ======-->
		<section class="member-details-wrapper " style="padding-top: 40px;">
			<div class="container">
				<div class="member-details">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/Deleep Chandralal.png" alt="TeamMember">
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name">Prof. Dileep Chandralal</h3>
						<span style="font-size: large; padding-top: 1.2rem;" class="pro">Honorary Ambassador 2019
						</span>

					</div>
					<div class="member-contact-info">
						<!-- <h3 class="title">Get In Touch</h3> -->
						<p>
							Emeritus Professor in International Communication, Okinawa University, Japan. Former Deputy
							Vice Chancellor of Okinawa University, Japan.
						</p>
					</div>
				</div>
			</div>
		</section>
		<!--====== Member Details End ======-->
		<!--====== Member Details Start ======-->
		<section class="member-details-wrapper" style="padding-top: 40px;">
			<div class="container">
				<div class="member-details">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/niranjani.png" alt="TeamMember">
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name">Prof. Eng. (Mrs) Niranjani Rathnayake </h3>
						<span style="font-size: large; padding-top: 1.2rem;" class="pro">Honorary Ambassador 2019</span>

						<p>
							Emeritus Professor in Civil Engineering, University of Moratuwa, Sri Lanka,
						</p>

						<p>
							Past President of Institution of Engineers Sri Lanka (IESL) 2017/2018,
						</p>

						<p>
							Environmental Impact Assessment Professional
						</p>

					</div>
					<div class="member-contact-info">
						<!-- <h3 class="title">Get In Touch</h3> -->
						<p>
							Team Leader in several multidisciplinary teams on Environmental Impact Assessment for large
							Projects and
							Strategic Environmental Assessments for Urban Development Plans

						</p>
					</div>
				</div>
			</div>
		</section>
		<br><br>
		<!--====== Member Details End ======-->

		<!-- Blog Details start -->
		<div class="post-details-wrap">
			<!-- <div class="post-thumb">
									<img src="assets/img/blog/12.jpg" alt="Image">
								</div> -->
			<!-- <div class="post-meta">
									<ul>
										<li><i class="far fa-user"></i><a routerLink='/blog-detail'>Nichel Jhon</a></li>
										<li><i class="far fa-calendar-alt"></i><a routerLink='/blog-detail'>25 Aug 2020</a></li>
										<li><i class="far fa-comments"></i><a routerLink='/blog-detail'>Comments (05)</a></li>
									</ul>
								</div> -->
			<div class="post-content">
				<h2 class="title">
					Real Life Story…
				</h2>
				<p class="fl">
					'Real Life' social enterprise is a family life coaching network of ambassadors, mentors, counselors
					and volunteers. This is a very innovative and insightful business model thoroughly conceptualized
					for the 'Real Life' by Step by Step Consultancy Services.
				</p>

				<p class="fl pt-1">
					We will be enhancing our network of intellectuals, social activists, professionals in
					coaching/counseling, who support us as roles of Ambassadors/ Mentors/Counselors and volunteers.

				</p>

				<p class="fl pt-1">
					We welcome 'change makers' with passion of social impact and outcome based social and environment
					projects.
				</p>

				<p class="fl pt-1">
					Our programs are embedded with Real Life values of mindfulness, strong relationship, loving family,
					happier lifestyle and sustainable future. We are initiating social projects and lifestyle
					transformation of Sri Lankan families through our edutainment family life coaching programs.
				</p>

				<p class="fl pt-1">
					Real Life is 'Good Market' approved social enterprise.&nbsp;<a
						href="https://reallife.goodmarket.global/" target="_blanck"
						class="color">https://reallife.goodmarket.global/</a>
				</p>

				<!-- <blockquote>
					Smashing Podcast Episode With Paul Boag What Is Conversion Optimization
					<span class="author">Bailey Dobson</span>
				</blockquote> -->
				<br><br>
				<h4 class="with-check"><i class="far fa-check-circle"></i> REAL LIFE APPROACH</h4>

				<p class="fl">
					Real Life programs are designed to meet the sustainable development goals. This mission will support
					Sri Lanka to grow mindset with this global vision and objectives.
				</p>

				<p class="fl pt-1">
					We create mindset and lifestyle change of individuals and children and families by providing quality
					education through edutainment life coaching programs and initiating social projects to practice the
					livelihood towards sustainable living.
				</p>

			</div>

			<div class="post-thumb ctr">
				<img src="../../../../assets/img/team/RL goals.jpg" alt="Image">
			</div>
			<!-- <div class="post-footer d-md-flex align-items-md-center justify-content-md-between">
				<div class="post-tag">
					<ul>
						<li class="title">Popular Tags :</li>
						<li><a routerLink='/blog-detail'>IT Consulting,</a></li>
						<li><a routerLink='/blog-detail'>Business,</a></li>
						<li><a routerLink='/blog-detail'>Corporate,</a></li>
					</ul>
				</div>
				<div class="post-share">
					<ul>
						<li class="title">Share :</li>
						<li><a routerLink='/blog-detail'><i class="fab fa-facebook-f"></i></a></li>
						<li><a routerLink='/blog-detail'><i class="fab fa-twitter"></i></a></li>
						<li><a routerLink='/blog-detail'><i class="fab fa-instagram"></i></a></li>
						<li><a routerLink='/blog-detail'><i class="fab fa-behance"></i></a></li>
					</ul>
				</div>
			</div> -->
		</div>
		<!-- Blog Details end -->
		<!-- <div class="row team-members align-items-center justify-content-between" id="teamSliderOne">
			<div class="col-lg-4">
				<div hidden class="team-member">

				</div>
			</div>
			<div class="col-lg-4">
				<div class="team-member">
					<div class="member-picture-wrap">
						<div class="member-picture">
							<img src="../../../../assets/img/team/founder.PNG" alt="TeamMember">
							<div class="social-icons">
								<a routerLink='/'>
									<i class="fab fa-facebook-f"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-twitter"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-behance"></i>
								</a>
								<a routerLink='/'>
									<i class="fab fa-instagram"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="member-desc">
						<h3 class="name"><a class="color">Eng. Jasmin Nanayakkara</a></h3>
						<span class="pro">Founder</span>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div hidden class="team-member">

				</div>
			</div>
		</div> -->
		<!-- tam box 1 -->

		<!-- <div class="row team-members" id="teamSliderOne">
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/01.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a class="color">Alia Whitney Johnson</a></h3>
							<span class="pro">First Ever Honorary Ambassador</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/02.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a class="color">Kenneth A. Spencer</a></h3>
							<span class="pro">Mentor</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/03.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a class="color">David K. Marble</a></h3>
							<span class="pro">Agent</span>
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="team-member">
						<div class="member-picture-wrap">
							<div class="member-picture">
								<img src="assets/img/team/04.jpg" alt="TeamMember">
								<div class="social-icons">
									<a routerLink='/'>
										<i class="fab fa-facebook-f"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-twitter"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-behance"></i>
									</a>
									<a routerLink='/'>
										<i class="fab fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="member-desc">
							<h3 class="name"><a class="color">Raymond Grimes</a></h3>
							<span class="pro">Voluntear</span>
						</div>
					</div>
				</div>
			</div> -->
	</div>
</section>
<!--====== Team Section End ======-->
<app-footer1></app-footer1>